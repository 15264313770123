<template>
  <div>
    <v-card
      max-width="500"
      class="my-md-8  mx-auto"
      :min-height="$vuetify.breakpoint.mdAndUp ? '625' : '550' "
      :class="$vuetify.breakpoint.smAndUp ? 'card-radius' : ''"
      color="#C3FFF5"
      flat
    >
      <v-row class="justify-lg-end justify-space-between mx-6 pt-4" >
         <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="primary--text" large  @click="$router.go(-1)" >mdi-arrow-left-drop-circle-outline</v-icon >
        <lang-swicther />
      </v-row>
      <v-card-text class="pt-16">
        <v-img
          :src="require('../../../assets/logo.png')"
          width="90px"
          contain
          class="mx-auto"
        />
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            :class="$vuetify.breakpoint.smAndDown ? 
            'text-center' : 'mx-12 text-center'"
          >
            {{ $t('verification.title') }}
          </v-col>
        </v-row>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-layout class="mt-5">
              <v-flex>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required|max:8|min:8"
                >
                  <v-text-field
                    class="custom-field mx-auto "
                    :error-messages="errors"
                    v-model="phoneNumber"
                    :placeholder="$t('verification.phoneNumber')"
                    required
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type = "number"
                    maxlength = "8"
                    outlined
                    rounded
                    dense
                  ></v-text-field>
                  
                </ValidationProvider>
              </v-flex>
            </v-layout>
            <v-layout justify-center>
              <v-btn
                depressed
                rounded
                color="primary custom-font"
                class="black--text font-weight-light mt-2"
                :width="$vuetify.breakpoint.xs ? 220 : 260"
                type="submit"
                :disabled="phoneNumber.length!==8"
                :loading="btn_loading"
                >{{
                  $t('verification.btnTitle')
                }}</v-btn
              >
            </v-layout>
          </form>
        </ValidationObserver>
        <p v-if="errorFromApi" class="red--text text-center mt-6">
          {{ errorFromApi }}
        </p>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col class="text-center custom-font">{{
              $t('verification.alreadyAcc') + ' '
            }}<b
              @click="$router.push('/')">{{
              $t('verification.login')
              }}</b></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { userPhoneVerification } from "../../methods/userPhoneVerification"
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} cannot be empty",
});
export default {
  data () {
    return {
      btn_loading: false,
      errorFromApi: null,
      phoneNumber: ''
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    "lang-swicther": () => import("@/core/components/LangSwitch")
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate();
      this.btn_loading = true;
      userPhoneVerification({ phone: this.phoneNumber }).then(response => {
      this.btn_loading = false;
        if (response.data) {
          sessionStorage.setItem('USER_PHONE', this.phoneNumber)
          this.$router.push('/verify_phone')
        }
        if (response.graphQLErrors) {
          this.errorFromApi = response.graphQLErrors[0].message;
          setTimeout(() => { this.errorFromApi = null }, 5000)
        }
      })
    }
  }
}
</script>

<style scoped>
.custom-field >>> .v-input__slot{
  background-color: white !important;
}
</style>
